import { template as template_72232cf8ee4048e7b3d5fa1e20ec025b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_72232cf8ee4048e7b3d5fa1e20ec025b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
