import { template as template_e13c3c4b8e83453d99eb7dccbdd6a30e } from "@ember/template-compiler";
const FKControlMenuContainer = template_e13c3c4b8e83453d99eb7dccbdd6a30e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
