import { template as template_34e496175a2b41bc90ba3066a60cb470 } from "@ember/template-compiler";
const WelcomeHeader = template_34e496175a2b41bc90ba3066a60cb470(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
