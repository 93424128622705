import { template as template_8f94741fe1a54db8bbd573129c7912de } from "@ember/template-compiler";
const EmptyState = template_8f94741fe1a54db8bbd573129c7912de(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
