import { template as template_48f24dfa033640bfbcf3df5eb1c1fe8b } from "@ember/template-compiler";
const SidebarSectionMessage = template_48f24dfa033640bfbcf3df5eb1c1fe8b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
